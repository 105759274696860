<template>
    <section>
        <b-navbar shadow spaced class="main-navigation" fixed-top>
            <template slot="brand">
                <b-navbar-item href="/">
                    <img alt="Gastrosuisse" src="~@/assets/images/gastrosuisse.png"
                         srcset="~@/assets/images/gastrosuisse@2x.png 2x, ~@/assets/images/gastrosuisse@3x.png 3x">
                </b-navbar-item>
            </template>
        </b-navbar>

        <section class="section">
            <div class="container">
                <h2>{{ title }}</h2>

                <br>

                <div v-if="!isValidView">
                    {{ invalidTokenMessage }}
                </div>
                <template v-else>
                    <form v-if="user" ref="form" @submit.prevent="save()">
                        <template v-if="isUserActivation">
                            <b-field :label="$t('First name')">
                                {{ user.first_name }}
                            </b-field>
                            <b-field :label="$t('Last name')">
                                {{ user.last_name }}
                            </b-field>

                            <b-field :label="$t('Username')">
                                {{ user.email }}
                            </b-field>
                        </template>

                        <b-field :label="$t('Password')">
                            <b-input v-model="user.password" autocomplete="new-password"
                                     required
                                     :validation-message="$t('Please fill out this field')"
                                     name="password"
                                     type="password" />
                        </b-field>
                        <b-field :label="$t('Password confirmation')">
                            <b-input v-model="user.password2" autocomplete="new-password"
                                     required
                                     :validation-message="$t('Please fill out this field')"
                                     name="password2"
                                     type="password" />
                        </b-field>

                        <b-message v-if="errors" type="is-danger" has-icon>
                            <ul>
                                <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                            </ul>
                        </b-message>

                        <div class="buttons" style="justify-content: flex-end">
                            <b-button class="is-primary is-small" native-type="submit">
                                {{ $t('Confirm password') }}
                            </b-button>
                        </div>
                    </form>
                </template>
            </div>
        </section>
    </section>
</template>

<script>
    import ApiService from '@/services/api.service'
    import { NotificationMixin } from '@/config/mixins'

    export default {
        name: 'UserActivation',
        mixins: [ NotificationMixin ],
        data() {
            return {
                user: null,
                errors: null,
                isValidView: this.$route.query.uid && this.$route.query.token,
                invalidTokenMessage: this.$t('Invalid token. Make sure to click on the link in the email or copy it exactly.')
            }
        },
        computed: {
            isUserActivation() {
                return this.$route.query.activate
            },
            title() {
                let title = this.$t('Password reset')
                if (this.isUserActivation)
                    title = this.$t('User activation')
                return title
            }
        },
        async mounted() {
            if (!this.isValidView)
                return

            try {
                const response = await ApiService.post(`/user/company_user/password_reset/`, {
                    uidb64: this.$route.query.uid,
                    token: this.$route.query.token
                })
                this.user = response.data
            } catch (e) {
                this.isValidView = false
            }
        },
        methods: {
            async save() {
                this.errors = null
                if (!this.$refs.form.reportValidity())
                    return

                try {
                    await ApiService.post(`/user/company_user/password_reset/`, {
                        uidb64: this.$route.query.uid,
                        token: this.$route.query.token,
                        password: this.user.password,
                        password2: this.user.password2
                    })
                    await this.$router.replace('/')
                } catch (e) {
                    if (e.response && e.response.data && e.response.data.password)
                        this.errors = e.response.data.password
                    else
                        this.notifyError(e.response ? e.response.data || e.response : e)
                }
            }
        }
    }
</script>
